import Vue from 'vue'
import VueRouter from 'vue-router'
import UserSystem from '../views/login/User.vue'
import Fenlei from '../views/login/Fenlei.vue'
import Img from '../views/login/ImgSystem'
import EFenLei from '../views/login/Efenlei'
import SFenLei from '../views/login/Sfenlei'
import SSFenLei from '../views/login/Ssfenlei'
import Shi from '../views/login/Shipin'
import AddShiPin from '../views/login/addShiPin'
import Home from '../views/Home.vue'
import AddShop from '../views/shipin/addShop'
import MeiUser from '../views/meijia/meiUser'
import MeiDian from '../views/meijia/meiDian'
import KuanShi from '../views/meijia/kuanShi'
Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect:'/welcome',
    children:[ 
      {
        path: '/welcome',
        name: 'Welcome',

        component: () => import( '../views/welcome/Welcome.vue')
      },
      {
        path: '/shipin',
        name: 'Shipin',

        component: () => import( '../views/shipin/Shipin.vue')
      },
      {
        path: '/vip',
        name: 'Vip',

        component: () => import( '../views/vip/Vip.vue')
      },

      {
        path: '/userSystem',
        component:UserSystem
      },
      {
        path: '/fenlei',
        component:Fenlei
      },
      {
        path: '/img',
        component:Img
      },
      {
        path: '/addShop',
        component:AddShop
      },
      {
        path: '/eFenLei',
        component:EFenLei
      },
      {
        path: '/sFenLei',
        component:SFenLei
      },
      {
        path: '/ssFenLei',
        component:SSFenLei
      },
      {
        path: '/shi',
        component:Shi
      },
      {
        path: '/addShiPin',
        component:AddShiPin
      },
      {
        path: '/meiUser',
        component:MeiUser
      },
      {
        path: '/meiDian',
        component:MeiDian
      },
      {
        path: '/kuanShi',
        component:KuanShi
      },
    ]
  },

  {
    path: '/about',
    name: 'About',

    component: () => import( '../views/About.vue')
  },
  {
    path: '/',
    name: 'Admin',

    component: () => import( '../views/login/AdminLogin.vue')
  },


]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

//路由导航守卫
// router.beforeEach((to, from, next) => {
//   //获取用户信息
//   let isLogin = window.sessionStorage.getItem('user');
//   if (isLogin !=null) {
//     next()
//   } else {
//     if (to.path === '/') {
//       next()
//     } else {
//       next('/')
//     }
//   }
// })



export default router;

