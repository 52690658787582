<template>
  <div class="addCommodity">

    <el-card class="box-card">
      <div slot="header">
        <h2>添加产品</h2>
      </div>
      <el-form
              :model="addCommodityForm"
              :rules="regRules"
              ref="addCommodityForm"
              label-width="100px"
              class="demo-ruleForm"
      >
        <el-form-item label="产品名称" prop="name">
          <el-input v-model="addCommodityForm.name"></el-input>
        </el-form-item>
        <el-form-item label="产品封面">
          <el-button
                  :type="addCommodityForm.savepath == '' ? 'danger' : 'success'"
                  style="width: 100%"
                  @click="showSavepath()"
          >上传产品封面</el-button
          >
        </el-form-item>
        <el-form-item label="产品相册">
          <el-button
                  :type="addCommodityForm.pics.length == 0 ? 'danger' : 'success'"
                  @click="showPics"
                  style="width: 100%"
          >上传产品相册</el-button
          >
        </el-form-item>
        <el-form-item label="产品尺寸" prop="jg">
          <el-input v-model="addCommodityForm.jg"></el-input>
        </el-form-item>
        <el-form-item label="产品介绍" prop="js">
          <el-input
                  type="textarea"
                  v-model="addCommodityForm.js"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品备注" prop="remake">
          <el-input v-model="addCommodityForm.remake"></el-input>
        </el-form-item>
        <el-form-item label="产品类型" prop="fid">
          <el-select style="width: 100%"
                     v-model="addCommodityForm.fid"
                     placeholder="请选择产品类型"
          >
            <el-option
                    v-for="item in petTypes"
                    :key="item.id"
                    :label="item.fname + '---' + item.efname + '---' + item.sfname"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button>重置</el-button>
          <el-button
                  type="primary"
                  @click="submitCommodityForm('addCommodityForm')"
          >添加</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
    <!--产品封面-->
    <el-dialog title="产品封面" :visible.sync="showPicVisible" width="30%">
      <el-upload
              :action="path + '/user/upload'"
              list-type="picture-card"
              :on-success="success"
              :limit="1"
              :on-exceed="savepathExceed"
              :on-remove="savepathRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
    </el-dialog>
    <!--产品相册-->
    <el-dialog
            title="产品相册最多上传二十张"
            :visible.sync="showPicsVisible"
            width="50%"
    >
      <el-upload
              :action="path + '/user/upload'"
              list-type="picture-card"
              :on-success="picssuccess"
              :limit="20"
              :on-exceed="picsExceed"
              :on-remove="picsRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showPicsVisible = false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: "AddCommodity",
    data() {
      return {
        regRules:
                {
                  name: [
                    { required: true, message: '请输入产品名称', trigger: 'blur' },
                    { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
                  ],
                  jg: [
                    { required: true, message: '请输入产品价格', trigger: 'blur' },
                  ]
                },
        showPicVisible: false,
        showPicsVisible: false,
        addCommodityForm: {
          id: "",
          name: "",
          jg: "",
          js: "",
          remake: "",
          fid: "",
          savepath: "",
          pics: [],
        },
        addCommodityRules: {},
        petTypes: [],
        queryCommodityForm: {
          fname: ''
        },
      };
    },
    methods: {
      showSavepath() {
        this.showPicVisible = true;
      },
      success(response, file) {
        this.addCommodityForm.savepath = response.data;
      },
      savepathRemove() {
        this.addCommodityForm.savepath = "";
      },
      savepathExceed() {
        this.$message.error("只能上传一张图片");
      },
      showPics() {
        this.showPicsVisible = true;
      },
      picssuccess(respone, file) {
        this.addCommodityForm.pics.push(respone.data);
      },
      picsExceed() {
        this.$message.error("只能上传二十张图片");
      },
      picsRemove(file) {
        let _this = this;
        this.addCommodityForm.pics.forEach(function (pic, index) {
          if (pic === file.response.data) {
            _this.addCommodityForm.pics.splice(index, 1);

          }
        });
      },

      queryDict(){
        this.$http.get('user/getSJiList',{params:{

          }}).then(res => {
          if(res.data.code === 200){
            this.petTypes = res.data.data
          }else{
            this.$message.error('获取列表失败')
          }
        })
      },
      submitCommodityForm(formName) {
        this.$refs[formName].validate(valid=>{
          if(this.addCommodityForm.fid == ''){
            this.$message.error("请选择分类！！！")
            return
          }
          this.$http
                  .post("/shop/addShop", this.addCommodityForm)
                  .then((res) => {
                    if (res.data.code === 200) {
                      this.$message.success("添加成功");
                      location.reload()
                    }
                  });
        })
      },
    },
    created() {
      this.queryDict();
    },
  };
</script>

<style scoped>
  .el-dialog__headerbtn {
    margin-top: 0px;
  }
</style>