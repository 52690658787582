import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/static/style.css'
import '@/assets/css/et.css'


import loading from './components/loading'
Vue.use(loading)

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


/axios/
import axios from 'axios'

axios.defaults.baseURL='https://www.dzpj.shop/pet' //http://localhost:8000/pet //https://chen.zaixianke.cn/pet
Vue.prototype.$http=axios
Vue.prototype.path='https://www.dzpj.shop/pet/' //http://112.125.123.4/imgs/
Vue.prototype.pathImg='https://www.dzpj.shop/pet/' //http://112.125.123.4/imgs/
//腾讯云点播
import TcVod from 'vod-js-sdk-v6'
Vue.prototype.$TcVod = TcVod

// const service = axios.create({
//   baseURL: 'http://localhost:8000/pet', // 请求的接口
//   timeout: 100000 // 请求超时时间
// })
//
// // 使用拦截器，定义全局请求头
// service.interceptors.request.use(config => {
//   // 在请求头中添加token
//   config.headers.Authorization = window.sessionStorage.getItem("token")
//   return config
// })
// // 设置 Vue.prototype.$http 为 axios 的别名：
// Vue.prototype.$http=service

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')





