<template>
    <div>
        <el-card class="box-card">

            <div>
                <el-form :model="queryCommodityForm"  ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row class="table-bar">
                        <el-col :span="14" style="text-align:left">
                            <el-select clearable  v-model="queryCommodityForm.major" placeholder="请选择" size="small">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col :span="10">
                            <el-button type="primary" @click="getUserList()" size="mini">查询</el-button>
                        </el-col>
                    </el-row>

                </el-form>
                <el-table
                        :data="UserList"
                        border
                        height="400px"
                        style="width: 100%">
                    <el-table-column
                            prop="name"
                            label="姓名"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="phone"
                            label="手机号"
                            width="120"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="major"
                            label="专业"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="politics"
                            label="政治成绩"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="english"
                            label="外语一成绩"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="courses"
                            label="专业一成绩"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="courses2"
                            label="专业二成绩"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="sum"
                            label="总成绩"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="rankNo"
                            label="专业内排名"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="createdtime"
                            label="录入时间"
                            width="180"
                    >
                    </el-table-column>

                </el-table>

                <div style="margin-left: 5rem; margin-top: 1em">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="pageNum"
                            :page-sizes="[8,20,50,100,1000]"
                            :page-size="pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total"
                    >
                    </el-pagination>
                </div>
            </div>
        </el-card>




    </div>

</template>

<script>
    export default {
        data() {

            return {

                queryCommodityForm: {
                    major:''
                },
                options:[
                    {
                        value: '刑法学',
                        label: '刑法学'
                    },
                    {
                        value: '刑事诉讼法学',
                        label: '刑事诉讼法学'
                    },
                    {
                        value: '侦查学',
                        label: '侦查学'
                    },
                    {
                        value: '国家安全学',
                        label: '国家安全学'
                    },
                    {
                        value: '法理学',
                        label: '法理学'
                    },
                    {
                        value: '宪法与行政法学',
                        label: '宪法与行政法学'
                    },
                    {
                        value: '法律史',
                        label: '法律史'
                    },
                    {
                        value: '人权法学',
                        label: '人权法学'
                    },
                    {
                        value: '监察法',
                        label: '监察法'
                    },
                    {
                        value: '人工智能法学',
                        label: '人工智能法学'
                    },
                    {
                        value: '知识产权法学',
                        label: '知识产权法学'
                    },
                    {
                        value: '民商法学',
                        label: '民商法学'
                    },
                    {
                        value: '经济法学',
                        label: '经济法学'
                    },
                    {
                        value: '民事诉讼法学',
                        label: '民事诉讼法学'
                    },
                    {
                        value: '环境资源法学',
                        label: '环境资源法学'
                    },
                    {
                        value: '国际法学',
                        label: '国际法学'
                    },
                    {
                        value: '社会法学',
                        label: '社会法学'
                    },
                    {
                        value: '民事执行法学',
                        label: '民事执行法学'
                    },
                    {
                        value: '医事法学',
                        label: '医事法学'
                    },
                    {
                        value: '法硕法学',
                        label: '法硕法学'
                    },
                    {
                        value: '法硕非法学',
                        label: '法硕非法学 '
                    },
                ],
                user: {
                    id: "",
                    userName: "",
                    password: "",
                    pic: "",
                    role:'',
                    fullscreen: false,
                },
                UserList:[],
                pageNum: 1,
                pageSize: 8,
                total: 0,

            }
        },
        created() {
            this.getNowTime();//默认时间
            this.getUserList()

        },
        methods: {

            handleSizeChange(pageSize) {

                this.pageSize = pageSize;
                this.getUserList();
            },
            handleCurrentChange(pageNum) {
                this.pageNum = pageNum;
                this.getUserList();
            },

            //默认时间
            getNowTime(){
                let now = new Date();
                let today = new Date(Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())).toISOString().slice(0, 10);

                this.queryCommodityForm.jssj = today;

                //一天前日期
                let oneweekdate = new Date(now-7*24*3600*1000);
                let y = oneweekdate.getFullYear();
                let m = oneweekdate.getMonth()+1;
                m= Number(m) < 10 ? "0"+m : m ;
                let d = Number(oneweekdate.getDate()) < 10 ? "0"+oneweekdate.getDate() : oneweekdate.getDate() ;
                let formatwdate = y+'-'+m+'-'+d;
                this.$set(this.queryCommodityForm, "kssj", formatwdate);

            },

            getUserList(){
                this.$http
                    .get("examination/getList", {
                        params: {
                            major:this.queryCommodityForm.major,
                            pageNum:this.pageNum,
                            pageSize:this.pageSize
                        },
                    }).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data.rows
                        this.pageNum = res.data.data.pageNum;
                        this.total = res.data.data.total;

                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .table-bar {
        width: 100%;
        text-align: right;
        margin: 8px 0px;
    }
</style>