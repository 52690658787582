<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>分类管理</el-breadcrumb-item>
                    <el-breadcrumb-item>分类四级管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm"  ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item  >
                                <el-input clearable  v-model="queryCommodityForm.ssfname" style="width: 150px" placeholder="分类名称"></el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :span="4">
                            <el-form-item >
                                <el-select v-model="queryCommodityForm.yfid" placeholder="一级分类" style="width: 100%" clearable >
                                    <el-option
                                            v-for="item in options_yi"
                                            :key="item.id"
                                            :label="item.fname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item >
                                <el-select v-model="queryCommodityForm.efid" placeholder="二级分类" style="width: 100%" clearable >
                                    <el-option
                                            v-for="item in options_yiJi"
                                            :key="item.id"
                                            :label="item.efname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item >
                                <el-select v-model="queryCommodityForm.sfid" placeholder="三级分类" style="width: 100%" clearable >
                                    <el-option
                                            v-for="item in options_sj"
                                            :key="item.id"
                                            :label="item.sfname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="2">
                            <el-button type="primary" @click="getUserList()">查询</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="show()">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form>

                <el-table
                        height="450"
                        :data="UserList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="ssfname"
                            label="分类名称"
                    >
                    </el-table-column>


                    <el-table-column
                            prop="fname"
                            label="所属一级分类"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="efname"
                            label="所属二级分类"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="sfname"
                            label="所属三级级分类"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="paixuhao"
                            label="单组排序号"
                    >
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="success"
                                    @click="editUser(scope.row)">修改</el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteUser(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog
                title="添加分类"
                :visible.sync="dialogVisible"
                width="40%"
                @close="dialogColsed"
        >

            <div style="margin: 20px;">
                <el-form :model="regForm" :rules="regRules" ref="regForm">
                    <el-form-item prop="sfid">
                        <el-select v-model="regForm.sfid" placeholder="请选择三级分类" style="width: 100%">
                            <el-option
                                    v-for="item in options_sj"
                                    :key="item.id"
                                    :label="item.fname + '---' + item.efname + '---' + item.sfname"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item  prop="ssfname">
                        <el-input v-model="regForm.ssfname" placeholder="请输入四级分类名称"></el-input>
                    </el-form-item>

                    <el-form-item  prop="paixuhao">
                        <el-input v-model="regForm.paixuhao" placeholder="请输入排序号" ></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitRegForm('regForm')">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改分类"
                :visible.sync="editDdialogVisible"
                width="40%"
                @close="editDdialogVisibleColsed"
        >
            <div style="margin: 20px;">
                <el-form :model="editForm" :rules="regRules" ref="editForm">
                    <el-form-item prop="sfid">
                        <el-select v-model="editForm.sfid" placeholder="请选择三级分类" style="width: 100%">
                            <el-option
                                    v-for="item in options_sj"
                                    :key="item.id"
                                    :label="item.fname + '---' + item.efname + '---' + item.sfname"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item  prop="ssfname">
                        <el-input v-model="editForm.ssfname" placeholder="请输入四级分类名称"></el-input>
                    </el-form-item>

                    <el-form-item  prop="paixuhao">
                        <el-input v-model="editForm.paixuhao" placeholder="请输入排序号" ></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {

            return {
                regRules:
                    {
                        sfid: [
                            { required: true, message: '请选择三级级分类', trigger: 'blur' },

                        ],
                        ssfname: [
                            { required: true, message: '请输入分类名', trigger: 'blur' },
                            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                        ],
                        paixuhao: [
                            { required: true, message: '请输入排序号', trigger: 'blur' },
                            { min: 1, max: 1000, message: '长度在 1 到 1000 个字符', trigger: 'blur' }
                        ]

                    },
                queryCommodityForm: {
                    ssfname: '',
                    efid:'',
                    yfid:'',
                    sfid:''
                },
                UserList: [],
                regForm: {
                    sfid:'',
                    ssfname:'',
                    paixuhao:''
                },
                editForm:{
                    id:'',
                    sfid:'',
                    ssfname:'',
                    paixuhao:''
                },
                fileList:[],
                options_yi:[],
                options_yiJi:[],
                options_sj:[],
                dialogVisible:false,
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
            this.getListYi()
            this.getListYiJi()
            this.getListsj()
        },
        methods: {
            // 关闭对话框清空数据
            dialogColsed() {
                this.$refs.regForm.resetFields();

            },
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();

            },
            submitRegForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/addSsjFenLei',this.regForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("添加成功")
                                this.$refs[formName].resetFields()
                                this.dialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            editUser(row){
                this.editDdialogVisible= true
                this.editForm.id = row.id
                this.editForm.ssfname = row.ssfname
                this.editForm.sfid = row.sfid
                this.editForm.paixuhao = row.paixuhao

            },


            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/updateSsjFenLei',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },

            getUserList(){
                this.$http.get('user/getSsJiList',{params:{
                        ssfname:this.queryCommodityForm.ssfname,
                        efid:this.queryCommodityForm.efid,
                        yfid:this.queryCommodityForm.yfid,
                        sfid:this.queryCommodityForm.sfid,
                    }}).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            getListYi(){
                this.$http.get('user/getListYiJi',{params:{
                        sfname:''
                    }}).then(res => {
                    if(res.data.code === 200){
                        this.options_yi = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            getListsj(){
                this.$http.get('user/getSJiList',{params:{

                    }}).then(res => {
                    if(res.data.code === 200){
                        this.options_sj = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            getListYiJi(){
                this.$http.get('user/getErJiList',{params:{
                        sfname:''
                    }}).then(res => {
                    if(res.data.code === 200){
                        this.options_yiJi = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            deleteUser(row){
                this.$confirm('确定删除该分类？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/user/deleteSsjFenLei/'+row.id).then(res => {
                        if(res.data.code === 200){
                            this.getUserList()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            show(){
                this.dialogVisible= true
            },

        }
    }
</script>

<style scoped>

</style>