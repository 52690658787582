<template>
    <div>
        <el-card class="box-card">
            <div slot="header" >
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>系统管理</el-breadcrumb-item>
                    <el-breadcrumb-item>分类二级管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm"  ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <el-form-item  >
                                <el-input clearable  v-model="queryCommodityForm.efname" style="width: 150px" placeholder="分类名称"></el-input>
                            </el-form-item>

                        </el-col>
                        <el-col :span="4">
                            <el-form-item >
                                <el-select v-model="queryCommodityForm.yfid" placeholder="一级分类" style="width: 100%" clearable >
                                    <el-option
                                            v-for="item in options_yiJi"
                                            :key="item.id"
                                            :label="item.fname"
                                            :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="10">
                            <el-button type="primary" @click="getUserList()">查询</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="show()">添加</el-button>
                        </el-col>
                    </el-row>
                </el-form>

                        <el-table
                                height="450"
                                :data="UserList"
                                border
                                style="width: 100%">
                            <el-table-column
                                    prop="efname"
                                    label="分类名称"
                            >
                            </el-table-column>

                            <el-table-column
                                    prop="paixuhao"
                                    label="单组排序号"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="fname"
                                    label="所属一级分类"
                            >
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            size="mini"
                                            type="success"
                                            @click="editUser(scope.row)">修改</el-button>
                                    <el-button
                                            size="mini"
                                            type="danger"
                                            @click="deleteUser(scope.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
            </div>
        </el-card>
        <el-dialog
                title="添加分类"
                :visible.sync="dialogVisible"
                width="40%"
                @close="dialogColsed"
               >

          <div style="margin: 20px;">
              <el-form :model="regForm" :rules="regRules" ref="regForm">
                  <el-form-item prop="yfid">
                      <el-select v-model="regForm.yfid" placeholder="请选择一级分类" style="width: 100%">
                          <el-option
                                  v-for="item in options_yiJi"
                                  :key="item.id"
                                  :label="item.fname"
                                  :value="item.id">
                          </el-option>
                      </el-select>
                  </el-form-item>

                  <el-form-item  prop="efname">
                      <el-input v-model="regForm.efname" placeholder="请输入二级分类名称"></el-input>
                  </el-form-item>

                  <el-form-item  prop="paixuhao">
                      <el-input v-model="regForm.paixuhao" placeholder="请输入排序号" ></el-input>
                  </el-form-item>

              </el-form>
          </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitRegForm('regForm')">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改分类"
                :visible.sync="editDdialogVisible"
                width="40%"
                @close="editDdialogVisibleColsed"
        >
            <div style="margin: 20px;">
                <el-form :model="editForm" :rules="regRules" ref="editForm">
                    <el-form-item prop="yfid">
                        <el-select v-model="editForm.yfid" placeholder="请选择一级分类" style="width: 100%">
                            <el-option
                                    v-for="item in options_yiJi"
                                    :key="item.id"
                                    :label="item.fname"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item  prop="efname">
                        <el-input v-model="editForm.efname" placeholder="请输入二级分类名称"></el-input>
                    </el-form-item>

                    <el-form-item  prop="paixuhao">
                        <el-input v-model="editForm.paixuhao" placeholder="请输入排序号" ></el-input>
                    </el-form-item>

                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {

            return {
                regRules:
                    {
                        yfid: [
                            { required: true, message: '请选择一级分类', trigger: 'blur' },

                        ],
                        efname: [
                            { required: true, message: '请输入分类名', trigger: 'blur' },
                            { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
                        ],
                        paixuhao: [
                            { required: true, message: '请输入排序号', trigger: 'blur' },
                            { min: 1, max: 1000, message: '长度在 1 到 1000 个字符', trigger: 'blur' }
                        ]

                    },
                queryCommodityForm: {
                    efname: '',
                    yfid:''
                },
                UserList: [],
                regForm: {
                    yfid:'',
                    efname:'',
                    paixuhao:''
                },
                editForm:{
                    id:'',
                    yfid:'',
                    efname:'',
                    paixuhao:''
                },
                fileList:[],
                options_yiJi:[],
                dialogVisible:false,
                editDdialogVisible: false
            }
        },
        created() {
            this.getUserList()
            this.getListYiJi()
        },
        methods: {
            // 关闭对话框清空数据
          dialogColsed() {
                this.$refs.regForm.resetFields();

            },
            editDdialogVisibleColsed() {
                this.$refs.editForm.resetFields();

            },
            submitRegForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                            this.$http.post('/user/addEjFenLei',this.regForm).then(res=>{
                                if(res.data.code==200){
                                    this.$message.success("添加成功")
                                    this.$refs[formName].resetFields()
                                    this.dialogVisible=false
                                    this.getUserList();
                                }
                                else{
                                    this.$message.error(res.data.data)
                                }
                            })
                        }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            editUser(row){
                this.editDdialogVisible= true
                this.editForm.id = row.id
                this.editForm.efname = row.efname
                this.editForm.yfid = row.yfid
                this.editForm.paixuhao = row.paixuhao

            },


            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/user/updateEjFenLei',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getUserList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },

            getUserList(){
                this.$http.get('user/getErJiList',{params:{
                        efname:this.queryCommodityForm.efname,
                        yfid:this.queryCommodityForm.yfid,
                    }}).then(res => {
                    if(res.data.code === 200){
                        this.UserList = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            getListYiJi(){

                this.$http.get('user/getListYiJi').then(res => {
                    if(res.data.code === 200){

                        this.options_yiJi = res.data.data
                    }else{
                        this.$message.error('获取列表失败')
                    }
                })
            },
            deleteUser(row){
                this.$confirm('确定删除该分类？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=> {
                    this.$http.delete('/user/deleteEjFenLei/'+row.id).then(res => {
                        if(res.data.code === 200){
                            this.getUserList()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(()=>{
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            show(){
                this.dialogVisible= true
            },

        }
    }
</script>

<style scoped>

</style>