<template>
  <div class="home" style="height: 100%">
    <el-container style="height: 100%">
      <el-header>
        <div class="divCss">
          <el-button type="text" @click="goWelcome">
            <h2 style="color: white; font-size: 30px; margin-top: 0px">
              后台管理系统
            </h2>
          </el-button>
        </div>

      </el-header>
      <el-container style="margin-top: 60px">
        <el-aside width="200px">
          <!--          <div style="height: 700px">-->
          <el-scrollbar class="customScrollbar" >
            <el-menu
                    style="height: 100%; position: fixed; width: 200px"
                    default-active="2"
                    class="el-menu-vertical-demo"
                    router
                    unique-opened

            >
              <el-submenu index="1" v-if="user.role == '0'">
                <template slot="title">
                  <i class="el-icon-s-custom" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">系统管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                          index="/userSystem"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-custom" ></i>
                    <span slot="title" class="innerSpanCss">用户管理</span>
                  </el-menu-item>
                </el-menu-item-group>



                <el-menu-item-group>
                  <el-menu-item
                          index="/img"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-picture-outline-round" ></i>
                    <span slot="title" class="innerSpanCss">轮播图管理</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="3" v-if="user.role == '0'">
                <template slot="title">
                  <i class="el-icon-s-goods" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">商品管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                          index="/shipin"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-goods" ></i>
                    <span slot="title" class="innerSpanCss">商品管理</span>
                  </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item
                          index="/addShop"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-goods" ></i>
                    <span slot="title" class="innerSpanCss">商品添加</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

              <el-submenu index="4" v-if="user.role == '0'">
                <template slot="title">
                  <i class="el-icon-more" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">分类管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                          index="/fenlei"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-operation" ></i>
                    <span slot="title" class="innerSpanCss">分类管理(一级)</span>
                  </el-menu-item>
                </el-menu-item-group>
                <el-menu-item-group>
                  <el-menu-item
                          index="/eFenLei"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-operation" ></i>
                    <span slot="title" class="innerSpanCss">分类管理(二级)</span>
                  </el-menu-item>
                </el-menu-item-group>

                <el-menu-item-group>
                  <el-menu-item
                          index="/sFenLei"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-s-operation" ></i>
                    <span slot="title" class="innerSpanCss">分类管理(三级)</span>
                  </el-menu-item>
                </el-menu-item-group>

              </el-submenu>

              <el-submenu index="6" v-if="user.role == 1">
                <template slot="title">
                  <i class="el-icon-video-camera" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">视频管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                          index="/shi"
                          style="margin-top: -20px"
                  >
                    <i class=" el-icon-video-camera" ></i>
                    <span slot="title" class="innerSpanCss">视频管理</span>
                  </el-menu-item>
                </el-menu-item-group>

              </el-submenu>
              <el-submenu index="6" v-if="user.role == 2">
                <template slot="title">
                  <i class="el-icon-setting" style="margin-left: -5px"></i>
                  <span class="spanCss" style="margin-left: 2px">数据管理</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item
                          index="/meiDian"
                          style="margin-top: -20px"
                  >
                    <i class="el-icon-bell" ></i>
                    <span slot="title" class="innerSpanCss">成绩管理</span>
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>

            </el-menu>
          </el-scrollbar>
          <!--          </div>-->
        </el-aside>

        <el-main>
          <router-view> </router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      user: {
        id: "",
        userName: "",
        password: "",
        pic: "",
        fullscreen: false,
      },
    };
  },
  methods: {
    goWelcome() {
      this.$router.push("/welcome");
    },
    goRescue() {
        let routeData = this.$router.resolve({ path: '/rhome'});
        window.open(routeData.href, '_blank');
    },
    screen() {
   let element = document.documentElement;
   if (this.fullscreen) {
    if (document.exitFullscreen) {
     document.exitFullscreen();
    } else if (document.webkitCancelFullScreen) {
     document.webkitCancelFullScreen();
    } else if (document.mozCancelFullScreen) {
     document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
     document.msExitFullscreen();
    }
   } else {
    if (element.requestFullscreen) {
     element.requestFullscreen();
    } else if (element.webkitRequestFullScreen) {
     element.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
     element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
     // IE11
     element.msRequestFullscreen();
    }
   }
   this.fullscreen = !this.fullscreen;
  },
  },
  created() {
    let user = JSON.parse(window.sessionStorage.getItem("user"));
    if (user != null) {
      this.user = user;
    }
  },
};
</script>
<!--scoped表示此页面样式对本页面有效-->
<style scoped>

.el-header,
.el-footer {
  background-color: #2745d7;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.el-aside {
  background-color: white;
  color: #333;

  line-height: 200px;
}

.el-main {
  background-color: white;
  color: #333;
}
.divCss {
  display: flex;
  justify-content: space-between;
}
.spanCss {
  margin-left: 10px;
  font-size: 17px;
}
.innerSpanCss {
  margin-left: 8px;
}
.customScrollbar{
  height:100%
}
</style>