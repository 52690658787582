<template>
    <div>
        <el-card class="box-card">
            <div slot="header">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>视频管理</el-breadcrumb-item>
                    <el-breadcrumb-item>视频管理</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div>
                <el-form :model="queryCommodityForm" ref="queryCommodityForm" class="demo-ruleForm">
                    <el-row :gutter="20">
                        <el-col :span="6">
                            <el-form-item label="视频名称" prop="account" size="mini">
                                <el-input v-model="queryCommodityForm.videoName" style="width: 150px"
                                          placeholder="视频名称"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="14">
                            <el-button type="primary" @click="getVideoList()" size="mini">查询</el-button>
                        </el-col>
                        <el-col :span="4">
                            <el-button type="primary" @click="show()" size="mini">上传视频</el-button>
                        </el-col>
                    </el-row>
                </el-form>
                <el-table
                        height="400"
                        :data="UserList"
                        border
                        style="width: 100%">
                    <el-table-column
                            prop="videoName"
                            label="视频名称"
                            width="200"
                    >
                    </el-table-column>

                    <el-table-column
                            prop="xuhao"
                            label="序号"
                            width="80"
                    >
                    </el-table-column>
                    <el-table-column
                            prop="videoTime"
                            label="上传时间"
                            width="180"
                    >
                    </el-table-column>

                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button
                                    size="mini"
                                    type="warning"
                                    @click="showVideo(scope.row)">查看
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="默认按钮"
                                    @click="updateVideo(scope.row)">修改
                            </el-button>
                            <el-button
                                    size="mini"
                                    type="danger"
                                    @click="deleteUser(scope.row)">删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

            </div>

            <div style="margin-left: 5rem; margin-top: 1em">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageNum"
                        :page-sizes="[8, 20, 30, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                >
                </el-pagination>
            </div>
        </el-card>
        <el-dialog :visible.sync="videoDialogVisible"  width="50%" title="查看视频">
            <div style="margin-top: 10px">
                <div style="margin: 0 auto">
                    <vueMiniPlayer  ref="vueMiniPlayer" :video="video" :mutex="true" />
                </div>
            </div>
        </el-dialog >
        <el-dialog
                title="修改"
                :visible.sync="editDdialogVisible"
                width="40%"
                @close="editDdialogVisibleColsed"
        >
            <el-form :model="editForm" :rules="regRules" ref="editForm">
                <el-form-item  prop="videoName">
                    <el-input v-model="editForm.videoName" placeholder="请输入视频名称"></el-input>
                </el-form-item>
                <el-form-item  prop="password">
                    <el-input v-model="editForm.xuhao" placeholder="请输入序号"></el-input>
                </el-form-item>

                <el-form-item  prop="savepath">
                    <el-upload
                            ref='upload'
                            :action="path + '/user/upload'"
                            list-type="picture-card"
                            :on-success="success"
                            :limit="1"
                            :on-exceed="savepathExceed"
                            :on-remove="savepathURemove"
                    >
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item  prop="remake">
                    <el-input v-model="editForm.remake" placeholder="请输入视频介绍"  type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="editDdialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitEditForm('editForm')">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="上传视频"
                :visible.sync="dialogVisible"
                width="50%"
                @close="dialogVisibleColsed"
        >
            <el-form :model="regForm" :rules="regRules" ref="regForm">
                <el-form-item prop="videoName">
                    <el-input v-model="regForm.videoName" placeholder="请输入视频名称"></el-input>
                </el-form-item>

                <el-form-item prop="xuhao">
                    <el-input v-model="regForm.xuhao" placeholder="请输入单组排序号"></el-input>
                </el-form-item>

                <el-form-item prop="videoUrl">
                    <div style="border: 1px solid #787676;width: 100%;padding: 20px">
                        <input type="file" accept=".mp4" id="files" ref="selectFile" @change="uploadVideoChanged($event)"/>
                        <el-button type="primary" size="mini" style="margin-right: 10px" @click="uploadVideoFile">
                            {{!vIsUploading?'点击上传':'正在上传'}}
                        </el-button>
                        <a-button @click="uploadVideoFileCancel" v-show="vIsUploading">取消上传</a-button>
                        <div>

                            <el-progress :percentage="vProgress*100" :status="vStatus"></el-progress>
                        </div>

                    </div>
                </el-form-item>

                <el-form-item prop="">

                    <div style="border: 1px solid #787676;width: 100%;padding: 20px">
                        <el-upload

                                class="upload-demo"
                                :action="path + '/user/upload'"
                                :on-preview="handlePreview"
                                :before-remove="beforeRemove"
                                :on-remove="savepathRemove"
                                :on-success="ssuccess"
                                multiple
                                :limit="1"
                                :on-exceed="handleExceed"
                                :file-list="fileListTp">
                            <el-button size="small" type="primary">封面上传</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
                        </el-upload>
                    </div>
                </el-form-item>

                <el-form-item prop="remake">
                    <el-input v-model="regForm.remake" placeholder="请输入视频介绍" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="submitRegForm('regForm')">确 定</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
    import Vue from 'vue'
    import vueMiniPlayer from 'vue-mini-player'
    import 'vue-mini-player/lib/vue-mini-player.css'
    Vue.use(vueMiniPlayer)
    export default {
        data() {

            return {
                regRules:
                    {
                        videoName: [
                            {required: true, message: '请输入视频名称', trigger: 'blur'},
                            {min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur'}
                        ],
                        xuhao: [
                            {required: true, message: '请输入序号', trigger: 'blur'},
                            {min: 1, max: 1000, message: '长度在 1 到 1000 个字符', trigger: 'blur'}
                        ]

                    },

                video: {
                    // 视频播放源
                    url: '',
                    // 视频海报
                    // cover: 'https://i.loli.net/2019/06/06/5cf8c5d9c57b510947.png',
                    muted: false, // 是否静音播放
                    loop: false, // 视频是否循环播放
                    preload: 'auto', // 视频预加载
                    poster: '', // 原生视频默认海报暂不设置
                    volume: 1, // 默认音量
                    autoplay: false, // 视频自动播放
                },
                queryCommodityForm: {
                    videoName: ''
                },
                activeName: '美甲',
                UserList: [],
                editForm:{
                    id:'',
                    videoName: '',
                    videoType: '',
                    fid:'',
                    xuhao:'',
                    savepath:'',
                    remake:'',
                },
                regForm: {
                    videoName: '',
                    videoType: '',
                    videoUrl: '',
                    savepath:'',
                    fileId: '',
                    fid:'',
                    remake:'',
                    xuhao:''

                },
                options:[],
                pageNum: 1,
                pageSize: 8,
                total: 0,
                fileList: [],
                fileListTp:[],
                dialogVisible: false,
                videoDialogVisible: false,
                editDdialogVisible: false,
                vFile: {},  //视频文件File对象
                vIsFileExist: false,  //File对象是否存在
                vUploader: {},  //腾讯云上传的初始化对象
                vProgress: 0,  //视频上传进度
                vStatus: 'active',  //视频上传状态,string类型： active正在上传，success成功，exception失败
                vIsUploading: false,  //是否正在上传
                csForm:{
                    ftype:''
                }
            }
        },
        created() {
            this.getVideoList()
        },
        methods: {
            handlePreview(file) {
                console.log(file);
            },
            handleExceed(files, fileListTp) {
                this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileListTp.length} 个文件`);
            },
            beforeRemove(file, fileListTp) {

            },
            handleClick(tab, event) {
                this.getVideoList()
            },
            success(response, file) {
                this.editForm.savepath=response.data;
                this.$refs['editForm'].validateField('savepath')
                this.$refs.resForm.resetFields();
            },
            // 关闭对话框清空数据
            editDdialogVisibleColsed() {

                this.$refs.editForm.resetFields();
                this.$refs.upload.clearFiles();

            },

            dialogVisibleColsed() {
                this.$refs.editForm.resetFields();
                this.$refs.upload.clearFiles();

            },
            savepathExceed() {
                this.$message.error("只能上传1张");
            },
            savepathURemove(file) {

            },
            savepathRemove() {
                this.regForm.savepath = "";
            },
            ssuccess(respone, file) {
                this.regForm.savepath=respone.data;
            },
            showVideo(row){
                this.videoDialogVisible = true;
                this.video.url = row.videoUrl
            },
            updateVideo(row){
                this.editDdialogVisible= true
                this.editForm.videoName = row.videoName
                this.editForm.id = row.id
                this.editForm.xuhao = row.xuhao
                this.editForm.videoType = row.videoType
                this.editForm.fid = row.fid
                this.editForm.savepath = row.savepath
                this.editForm.remake = row.remake
            },
            submitEditForm(formName){
                this.$refs[formName].validate(valid=>{
                    if(valid){
                        this.$http.post('/video/updateVideo',this.editForm).then(res=>{
                            if(res.data.code==200){
                                this.$message.success("修改成功")
                                this.$refs[formName].resetFields()
                                this.editDdialogVisible=false
                                this.getVideoList();
                            }
                            else{
                                this.$message.error(res.data.data)
                            }
                        })
                    }
                    else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            selectChange(){

            },
            change(){
                this.regForm.fid = ''
                this.csForm.ftype = this.regForm.videoType
                this.$http.post('user/getListFenLei',this.csForm).then(res => {
                    if (res.data.code === 200) {
                        this.options = res.data.data

                    } else {
                        this.$message.error('获取列表失败')
                    }
                })
            },
            updateChange(){
                this.editForm.fid = ''
                this.csForm.ftype = this.editForm.videoType
                this.$http.post('user/getListFenLei',this.csForm).then(res => {
                    if (res.data.code === 200) {
                        this.options = res.data.data
                    } else {
                        this.$message.error('获取列表失败')
                    }
                })
            },
            //选择视频文件
            uploadVideoChanged(e) {
                if (e.currentTarget.files[0]) {  //选择了文件
                    if (e.currentTarget.files[0].type == 'video/mp4') {
                        this.vFile = e.currentTarget.files[0]  //获取上传文件中的File对象信息
                        console.log(this.vFile)
                        this.vIsFileExist = true
                    } else {
                        this.$message.warning('仅支持mp4格式的视频上传')
                    }
                    // console.log(vFile.size/1024/1024)
                } else {  //取消选择文件
                    this.vFile = {}
                    this.vIsFileExist = false
                }
            },
            //腾讯云点播上传视频
            uploadVideoFile() {
                if (this.vIsFileExist == false) {
                    this.$message.warning('请先选择视频文件')
                    return
                } else if (this.vIsUploading) {
                    this.$message.warning('正在上传中，请勿重复上传')
                    return
                } else if (this.vStatus == 'success') {
                    this.$message.warning('当前视频已上传完毕，请勿重复上传')
                    return
                }
                //获取腾讯云点播视频上传签名,这里注意：必须用函数表达式这种方式定义getSignature函数才行(如下)，使用Vue的传统方式：先声明getSignature(){}函数再this.getSignature()调用这种方式不会生效也不报错。这是个坑
                let getVideoSignature = () => {
                    return this.$http.post('/user/getQianMing').then(res => {
                        return res.data.data
                    }).catch(() => {
                    })

                }
                let tcVod = new this.$TcVod({   //腾讯云-添加签名
                    getSignature: getVideoSignature
                })
                this.vUploader = tcVod.upload({   //腾讯云-初始化上传功能
                    mediaFile: this.vFile,
                })
                this.vStatus = 'active'   //正在上传状态
                this.vIsUploading = true   //是否正在上传
                this.vUploader.on('media_progress', (info) => {  //获取上传进度信息
                    this.vProgress = info.percent
                })

                this.vUploader.done().then(res => {   //上传完成回调
                    this.$message.success('视频文件上传成功')
                    this.vStatus = 'success'
                    this.vIsUploading = false
                    this.regForm.videoUrl = res.video.url
                    this.regForm.fileId = res.fileId
                }).catch(() => {
                    this.$message.warning('视频文件上传失败')
                    this.vStatus = 'exception'
                    this.vIsUploading = false
                })
            },
            //取消上传
            uploadVideoFileCancel() {
                this.vUploader.cancel()
                this.$message.info('视频文件上传已取消')
                this.vStatus = 'active'
                this.vProgress = 0
                this.TpProgress = 0
                this.vIsUploading = false
            },

            //删除文件地址
            cancelUrl() {
                this.newsVideoUrl = ""
                this.vStatus = 'active'
                this.vProgress = 0
                this.TpProgress = 0
            },

            // 关闭对话框清空数据
            dialogColsed() {
                this.$refs.regForm.resetFields();

            },

            submitRegForm(formName) {
                var that = this
                this.$refs[formName].validate(valid => {
                    if (valid) {

                        if (this.regForm.fileId == '') {
                            this.$message.error("视频参数有误，请重新上传或刷新")
                            return
                        }
                        this.$http.post('/video/addVideo', this.regForm).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success("上传成功")
                                this.$refs[formName].resetFields()
                                this.vProgress = 0;
                                this.vFile = {};
                                this.beforeRemove()
                                this.fileListTp=[]
                                this.fileList=[]
                                this.dialogVisible = false
                                this.getVideoList();
                                location.reload()
                            } else {
                                this.$message.error(res.data.data)
                            }
                        })
                    } else {
                        this.$message.error('表单填写有误请检查')
                    }
                });
            },
            handleSizeChange(pageSize) {
                this.pageSize = pageSize;
                this.getVideoList(this.$route.params.shopType);
            },
            handleCurrentChange(pageNum) {
                this.pageNum = pageNum;
                this.getVideoList(this.$route.params.shopType);
            },
            getVideoList(){
                this.$http
                    .get("/video/getList", {
                        params: {
                            videoName: this.queryCommodityForm.videoName,
                            pageNum: this.pageNum,
                            pageSize: this.pageSize,
                        },
                    })
                    .then((res) => {
                        if (res.data.code === 200) {
                            this.UserList = res.data.data.rows
                            this.pageNum = res.data.data.pageNum;
                            this.total = res.data.data.total;
                        } else {
                            this.$message.error("获取失败");
                        }
                    });
            },
            deleteUser(row) {
                this.$confirm('确定删除该视频？, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$http.delete('/video/delete/' + row.id).then(res => {
                        if (res.data.code === 200) {
                            this.getVideoList()
                        }
                    });
                    this.$message({
                        type: 'success',
                        message: '删除成功!',
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            },
            show() {
                this.dialogVisible = true
            },

        }
    }
</script>

<style scoped>

</style>